import EstadosBrasileiros from 'src/core/mixin/EstadosBrasileiros'
import Carregando from 'src/components/CarregandoGeral/Carregando.vue'
import AgendamentoModel from 'src/model/usuario/AgendamentoModel'
import NotificacaoMixin from 'src/core/mixin/NotificacaoMixin'
import vTopFilters from 'src/core/filters/vTopFilters'
import Modal from 'src/components/Modal/Modal.vue'
import { LocalStorage, clone } from 'quasar'

export default {
  name: 'Videochamada',
  components: { Modal, Carregando },
  mixins: [EstadosBrasileiros, vTopFilters, NotificacaoMixin],
  props: ['videochamada'],
  data () {
    return {
      modelAgendamento: new AgendamentoModel(),

      logado: LocalStorage.getItem('logado'),
      usuarioSelecionado: LocalStorage.getItem('logado'),
      anuncioId: null,
      anuncioId2: null,

      dataAgendamento: '',
      horarioAgendamento: '',

      salvando: false,
      carregando: true,

      horarioOptions: [
        { label: '09:30', value: '09:30:00' },
        { label: '10:30', value: '10:30:00' },
        { label: '11:30', value: '11:30:00' },
        { label: '13:00', value: '13:00:00' },
        { label: '14:30', value: '14:30:00' },
        { label: '15:00', value: '15:00:00' },
        { label: '16:00', value: '16:00:00' },
        { label: '17:00', value: '17:00:00' },
        { label: '18:00', value: '18:00:00' }
      ],

      // Modal
      modalTipo: '',
      step: 1,
      value: false,
      dados: {},
      possuiCompromisso: false
    }
  },
  computed: {
    locale () {
      const _meses = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
      const _dias = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado']
      return {
        months: _meses,
        monthsShort: _meses.map(mes => mes.substr(0, 3)),
        days: _dias,
        daysShort: _dias.map((mes) => mes.substr(0, 3))
      }
    }
  },
  mounted () {
    this.anuncioId = this.$route.params.id

    if (this.logado && this.logado.id !== null && this.logado.roles && this.logado.roles.find(e => e.name === 'Vendedor')) {
      if (LocalStorage.has('usuarioSelecionado')) {
        this.usuarioSelecionado = LocalStorage.getItem('usuarioSelecionado')
      }
    }

    if (this.logado) {
      if (this.anuncioId) {
        if (this.videochamada) {
          this.responseGetAgendamento(this.videochamada)
        } else {
          this.getAgendamento()
        }
      } else {
        this.notificacao('aviso', 'Nenhum Anuncio Selecionado!', 3000)
        this.$router.go(-1)
      }
    } else {
      this.notificacao('aviso', 'Você esta deslogado!', 3000)
      this.$router.go(-1)
    }
  },
  methods: {
    toggleModal (tipo, { valorTarifa } = {}) {
      this.value = !this.value
      this.modalTipo = tipo
      this.dados = { valorTarifa: valorTarifa }
    },

    callbackAcao () {
      if (this.modalTipo === 'videochamada') {
        this.value = false
      }
      if (this.modalTipo === 'reagendar') {
        this.possuiCompromisso = false
        this.step = 2
        this.value = false
      }

      if (this.modalTipo === 'desmarcar') {
        this.deletarAgendamento()
        this.value = false
      }
    },

    getAgendamento () {
      const search = { tipo: 'Videochamada', usuario_id: this.usuarioSelecionado.id, anuncio_id: this.anuncioId }
      const params = { buscaGrid: search, searchJoin: 'and' }

      this.modelAgendamento.getListagem({ params }).then((res) => {
        const form = res.dados && res.dados.length > 0 ? res.dados[0] : null
        this.responseGetAgendamento(form)
      }).catch(error => {
        this.notificacao('erro', error.msg)
        console.error(error)
        this.carregando = false
      })
    },

    responseGetAgendamento (form) {
      this.carregando = false
      this.salvando = false

      if (form) {
        if (form.data_atendimento) {
          this.possuiCompromisso = true
          const data = form.data_atendimento.split(' ')

          this.dataAgendamento = data[0].replace(/-/g, '/')
          this.horarioAgendamento = data[1]
        }

        this.modelAgendamento.form = form
      }
    },

    validarDataAgendamento () {
      let dtPassado = false

      let dataAgendamento = this.dataAgendamento ? this.dataAgendamento.replace(/\//g, '-') : null
      const hoje = this.$options.filters.hojeEng()
      const horaAgora = this.$options.filters.horaHoje()

      // Validar se data do agendamento esta no passado
      if (dataAgendamento < hoje) {
        this.notificacao('aviso', 'Não é permitido agendar no passado!')
        dataAgendamento = hoje
        dtPassado = true
      }
      /* */

      // Validar se hora do agendamento esta no passado
      if (dataAgendamento === hoje) {
        const horarioAgendamento = this.$options.filters.hora(`${dataAgendamento} ${this.horarioAgendamento}`)
        const horaAgoraSonumero = this.$options.filters.soNumeros(horaAgora)
        const horarioAgendamentoSonumero = this.$options.filters.soNumeros(horarioAgendamento)

        if (horarioAgendamentoSonumero < horaAgoraSonumero) {
          console.error('opaaa hora do passado!')

          // exibir notificacao de erro
          if (!dtPassado) {
            this.notificacao('aviso', 'Não é permitido agendar no passado!')
            dtPassado = true
          }
          /* */

          // Colocar um proximo horario do futuro ou presente
          const encontradoHoraDisponivel = this.horarioOptions.find(e => {
            const value = this.$options.filters.hora(`${dataAgendamento} ${e.value}`)
            const valueSonumero = this.$options.filters.soNumeros(value)
            return valueSonumero >= horaAgoraSonumero
          })

          this.horarioAgendamento = encontradoHoraDisponivel ? encontradoHoraDisponivel.value : null
          /* */
        }
      }
      /* */

      // eslint-disable-next-line
      this.dataAgendamento = dataAgendamento ? dataAgendamento.replace(/\-/g, '/') : null

      return dtPassado
    },

    submitAgendamento () {
      if (this.validarDataAgendamento()) {
        return false
      }

      this.salvando = true

      const aviso = this.notificacao('enviando')
      const send = clone(this.modelAgendamento.form)
      send.anuncio_id = this.anuncioId
      send.usuario_id = this.usuarioSelecionado.id
      send.tipo = 'Videochamada'

      if (this.$route.params.dealId) {
        send.deal_id = this.$route.params.dealId.toString()
      } else if (LocalStorage.has('dealId')) {
        send.deal_id = (LocalStorage.getItem('dealId')).toString()
      }

      if (this.dataAgendamento && this.horarioAgendamento) {
        send.data_atendimento = `${this.dataAgendamento.replace(/\//g, '-')} ${this.horarioAgendamento}`
      } else {
        this.notificacao('aviso', 'Data de Agendamento Obrigatorio')
        return false
      }

      this.modelAgendamento.salvar(send).then((res) => {
        aviso()
        this.notificacao('salvo', res.msg, 3000)
        this.$emit('atualizarAgendamemto')
        this.getAgendamento()
        this.toggleModal('videochamada')
        if (this.$q.screen.lt.sm) {
          this.$router.push({ name: 'painel-inicio' })
        }
      }).catch(error => {
        aviso()
        this.notificacao('erro', error.msg)
        console.error(error)
        this.salvando = false
      })
    },

    deletarAgendamento () {
      if (this.modelAgendamento.form.id) {
        this.carregando = true

        const notify = this.notificacao('enviando', 'Desmarcado...')
        this.modelAgendamento.deletar(this.modelAgendamento.form.id).then(() => {
          this.notificacao('salvo', 'Desmarcado com sucesso', 8000)
          notify()

          this.modelAgendamento = new AgendamentoModel()
          this.possuiCompromisso = false
          this.carregando = false
        }).catch((error) => {
          this.notificacao('erro', error.msg, 8000)
          this.carregando = false
        })
      } else {
        this.notificacao('aviso', 'Não existe compromisso marcado!', 8000)
      }
    }
  }
}
